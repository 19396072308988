import React from "react"

//import GatsbyConfig from '../../gatsby-config'


import 'isomorphic-fetch'; //index file create



// Import layouts here
import Suwak from "../../layouts/Suwak"

import Menu from "../../layouts/menu";
// import Header from "../../layouts/header";
import OURPLANTS from "../../layouts/OURPLANTS";
import Footer from "../../layouts/footer";
// Import styles here
import '../../styles/global.css';
import '../../styles/aboutus.css';
// plugins
import { Helmet }   from "react-helmet";
import i18next from '../../i18n/config';
// import favicon from '../img/favi_icon_16px.png';
//      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />

// const $ = require("jquery")



export default  class Home extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        i18next.changeLanguage(this.props.pageContext.language, (err, t) => {
        });

        // if (typeof window !== `undefined`) {
        //     document.location.href = "/AboutUs";
        // }



        // return (
        //         <React.Fragment></React.Fragment>
        //         );


        return (
                <React.Fragment>
                    <div className="App" >
                        <Helmet>
                            <meta charSet="utf-8" />
                            <link rel="canonical" href="https://www.instanta.pl/" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <link rel="icon" type="image/png" href={require('../../img/template/favicon.png')} sizes="16x16" />
                            <title>Instanta Coffee Brand</title>
                            <meta name="Description" content="The company specializes in the production of private label coffee" />
                            <meta name="Keywords" content="private label coffee" />
                        </Helmet>
                        <div class="wrapper">
                            <Menu/>
                            <OURPLANTS/>
                            <Footer/>
                            <Suwak/>
                        </div>
                    </div>

                </React.Fragment>

                )
    }
}
